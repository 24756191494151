import { Icon } from '@iconify/react/dist/iconify.js'

interface ISideBarButton {
  varient: 'primary' | 'secondary'
  item: any
  active?: boolean
  onClick?: any
  clasName?: string;
  alertConfig?: any
}

const SideBarButton = ({
  varient,
  item,
  active = false,
  onClick,
  clasName,
  alertConfig
}: ISideBarButton) => {

  const alertAnimationClasses = 'relative animate-buttonAnimation bg-[#F4E7C5]';
  return (
    <div
      className={`mx-auto my-2 flex min-h-[4.2rem] w-[80%] cursor-pointer flex-col justify-center rounded-lg border p-2 text-center
                ${active && varient === 'secondary' ? 'bg-[#161B1F]' : ''}
                ${active && varient === 'primary' ? 'bg-[#175CD3]' : ''}
                ${!active && 'border-[#E9EAEB]'}
                ${active && 'border-transparent'},
                ${clasName}
                ${alertConfig?.[item.label]?.length > 0 && alertAnimationClasses}
            `}
      onClick={() => {
        onClick(item)
      }}
    >
      <div className='m-auto'>
        <Icon
          icon={item.icon}
          style={{ color: `${active ? '#FFFFFF' : 'grey'}` }}
          width='1.5em'
          height='1.5em'
        />
      </div>
      <div>
        <p
          className={` text-sm ${active ? 'text-[#FFFFFF]' : 'text-[#5B6169]'} `}
        >
          {item.label}
        </p>
      </div>
      {varient === 'primary' && (
        <hr
          className={`m-auto my-2 h-[4px] w-[22px] rounded-md border 
                ${active ? 'border-[#D9AB06] bg-[#D9AB06]' : 'border-[#E9EAEB] bg-[#E9EAEB]'}
            `}
        />
      )}
    </div>
  )
}

export default SideBarButton
